.fontStyles {
    /* --font-40px: 40px;
    --font-34px: 34px;
    --font-30px: 30px;
    --font-25px: 25px;
    --font-22px: 22px;
    --font-21px: 21px;
    --font-20px: 20px;
    --font-18px: 18px;
    --font-16px: 16px;
    --font-14px: 14px;
    --font-13px: 13px;
    --font-12px: 12px; */

    --font-40px: 2.45em;
    --font-36px: 2.25em;
    --font-34px: 2.1em;
    --font-32px: 2em;
    --font-30px: 1.9em;
    --font-28px: 1.8125em;
    --font-25px: 1.5625em;
    --font-24px: 1.5em;
    --font-22px: 1.4em;
    --font-21px: 1.3em;
    --font-20px: 1.25em;
    --font-19px: 1.2em;
    --font-18px: 1.125em;
    --font-16px: 1em;
    --font-15px: 0.9375em;
    --font-14px: 0.875em;
    --font-13px: 0.8125em;
    --font-12px: 0.75em;
    --font-10px:0.6250em;
    
    --font-large:large;
    --font-wtbold: bold;
    --font-Extra-Bold: 900;
    --font-Bold: 700;
    --font-Semi-Bold: 600;
    --font-Medium: 500;
    --font-semi-normal:460;
    --font-Normal: 400;
    --font-Light: 300;
    --font-Extra-Light : 200;
    --font-Thin: 100;

    --font-italic: italic;
    --font-normal-: normal;
    --font-solid-: solid;

    --ff-montserrat: "Montserrat", sans-serif;
    --ff-rhas: "Roboto", "Helvetica", "Arial", sans-serif;
    --ff-awesome: "Font Awesome\ 5 Free";
    --ff-vs: "Verdana, sans-serif";
    --ff-fa: "FontAwtesome";
    --ff-rs: "Roboto", sans-serif;
    /*need to import this fonts file level refer rptcertificate.scss */
    --ff-OldEnglishTextMT2: OldEnglishTextMT2;
    --ff-Cambo: Cambo;
    --ff-Californian: Californian;
    /* --ff-mon: 'Montserrat';
    --ff-roboto: 'Roboto';
    --ff-nun: 'Nunito';
    --ff-rs: "Roboto", sans-serif;
    --ff-fawt: "Font Awtesome 5 Free";
    --ff-vs: Verdana, sans-serif;
    --ff-f5: "Font Awtesome\ 5 Free";
    --ff-auto: auto;
    --ff-inherit: inherit;
    --ff-ms: "Montserrat", sans-serif;
    --ff-segoe: "Segoe";
    --ff-segoeui: 'Segoe UI'; */

}
